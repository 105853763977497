// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
import './lib/audioplayer.js';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import 'lite-youtube-embed/src/lite-yt-embed.js';
import 'details-element-polyfill';
import imagesLoaded from 'imagesLoaded';
import Masonry from 'masonry-layout';
import './nav.js';
import {Luminous, LuminousGallery} from 'luminous-lightbox';

gsap.registerPlugin(ScrollTrigger);

if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log('HMR');
  });
}

const tl = gsap.timeline({repeat: -1});

tl.set('.vinyl-record-tracks', {transformOrigin: 'center'});
tl.to('.vinyl-record-tracks', {rotate: 360, duration: 1.8, ease: 'none'});

const galleryContainer = document.querySelectorAll(
  '[data-page="gallery"] .gallery-container',
);
if (galleryContainer) {
  [...galleryContainer].forEach((container) => {
    imagesLoaded(container, () => {
      new Masonry(container, {
        // options
        itemSelector: '.gallery-item',
        columnWidth: '.gallery-sizer',
        percentPosition: true,
      });
    });
  });
}

new LuminousGallery(document.querySelectorAll('.gallery-item'));

// gsap.to('.header__image', {
//   yPercent: -20,
//   ease: 'none',
//   scrollTrigger: {
//     trigger: '.header',
//     // start: "top bottom", // the default values
//     // end: "bottom top",
//     scrub: true,
//   },
// });

// gsap.to('.main-content', {
//   yPercent: -5,
//   ease: 'none',
//   scrollTrigger: {
//     trigger: '.page-wrapper',
//     // start: "top bottom", // the default values
//     // end: "bottom top",
//     scrub: true,
//   },
// });
