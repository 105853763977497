// @ts-check
import * as focusTrap from 'focus-trap';
import gsap from 'gsap';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';
import Ready from './lib/ready.js';

gsap.registerPlugin(ScrollToPlugin);

/**
 * @type {HTMLElement|null}
 */
const nav = document.querySelector('.nav-holder');
/**
 * @type {NodeListOf<HTMLElement>}
 */
const navItems = document.querySelectorAll('#menu-main-menu li');
const topLevelNavItems = document.querySelectorAll('#menu-main-menu > li');
const hamburger = document.querySelector('.menu-icon');
const menuTrap = focusTrap.createFocusTrap('.nav');
/**
 * @type { boolean }
 */
let navVisible = false;

function init() {
  if (!nav) return;
  if (window.innerWidth < 800) {
    gsap.set(nav, {x: '100%'});
  } else {
    nav.style.removeProperty('transform');
  }
}

function toggleNav() {
  navVisible = !navVisible;
  if (navVisible) {
    menuTrap.activate();
    document.body.classList.add('nav-open');
    nav.classList.add('open-menu');
    nav.classList.remove('closed-menu');
    gsap.set(navItems, {y: '-15px', autoAlpha: 0});
    gsap.set(topLevelNavItems, {borderColor: 'transparent'});
    gsap.to(nav, 0.2, {x: '0', ease: 'power1', duration: 0.4});
    gsap.to(navItems, {
      autoAlpha: 1,
      y: 0,
      stagger: 0.02,
      delay: 0.2,
      overwrite: 'auto',
    });
    gsap.to(topLevelNavItems, {
      borderColor: '#c29436',
      delay: 0.2,
      stagger: 0.02,
    });
    hamburger.setAttribute('aria-label', 'close navigation');
  } else {
    menuTrap.deactivate();
    gsap.to(nav, 0.2, {
      x: '100%',
      onComplete: () => {
        document.body.classList.remove('nav-open');
      },
    });
    gsap.set(navItems, {y: '-15px', autoAlpha: 0, overwrite: 'auto'});
    hamburger.setAttribute('aria-label', 'open navigation');
  }
}

function outsideClick(event, notelem) {
  let clickedOutsideEl = true;
  const len = notelem.length;
  for (let i = 0; i < len; i++) {
    if (event.target == notelem[i] || notelem[i].contains(event.target)) {
      clickedOutsideEl = false;
    }
  }
  if (clickedOutsideEl) return true;
  return false;
}

Ready(() => {
  init();
  // Trap focus on the menu once it's revealed
  hamburger.addEventListener('click', toggleNav);
  window.addEventListener('resize', init);

  document
    .querySelectorAll('.main-nav__list > .menu-item-has-children > a')
    .forEach((el) => {
      el.addEventListener('click', (e) => {
        if (window.innerWidth < 800) {
          toggleNav();
        } else if (el.parentElement.classList.contains('sub-menu-visible')) {
          el.parentElement.classList.remove('sub-menu-visible');
        } else {
          e.preventDefault();
          el.parentElement.classList.add('sub-menu-visible');
          // Safari was registering this within the existing click
          setTimeout(() => {
            window.addEventListener(
              'click',
              (e) => {
                if (outsideClick(e, el.parentElement)) {
                  el.parentElement.classList.remove('sub-menu-visible');
                  el.parentElement.classList;
                }
              },
              {once: true},
            );
          }, 500);
        }
      });
    });

  const navEl = document.querySelector('.nav');
  const navElItemsWithChildren = navEl.querySelectorAll(
    '.menu-item-has-children',
  );

  navElItemsWithChildren.forEach((item) => {
    const link = item.querySelector('a');
    link.setAttribute('aria-expanded', 'false');

    link.setAttribute('role', 'button');
    link.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      const link = e.target;
      const parent = link.closest('.menu-item-has-children');
      parent.classList.toggle('open-menu');

      if (parent.classList.contains('open-menu')) {
        link.setAttribute('aria-expanded', 'true');
      } else {
        link.setAttribute('aria-expanded', 'false');
      }
    });

    item.addEventListener('keydown', (e) => {
      const parentItem = e.target.closest('.menu-item-has-children');
      if (parentItem && e.key === 'Escape') {
        e.target.blur();

        if (parentItem.nextSibling) {
          parentItem.nextSibling.querySelector('a').focus();
        } else {
          document.querySelector('.main a, .main button, .main input').focus();
        }
      }
    });
  });
});
